import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TipsHeading = makeShortcode("TipsHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TipsHeading frontmatter={props.pageContext.frontmatter} mdxType="TipsHeading" />
    <p>{`If you plan on developing with the Mechanic platform for an extended period of time, you might wonder what tooling is available to you beyond the in-app task editor.`}</p>
    <p>{`Tim Mackey's super awesome extension to VS Code, Mechanic Liquid Support, is amazing.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "liquid"
    }}><pre parentName="div" {...{
        "className": "language-liquid"
      }}><code parentName="pre" {...{
          "className": "language-liquid"
        }}><span parentName="code" {...{
            "className": "token liquid language-liquid"
          }}><span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`{%`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`assign`}</span>{` anvils `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` result`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`products`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`edges `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"node"`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`where`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"productType"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Anvil"`}</span>{` `}<span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`%}`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token liquid language-liquid"
          }}><span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`{%`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`assign`}</span>{` bricks `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` result`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`data`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`products`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`edges `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`map`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"node"`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function filter"
            }}>{`where`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"productType"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Brick"`}</span>{` `}<span parentName="span" {...{
              "className": "token delimiter punctuation"
            }}>{`%}`}</span></span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      